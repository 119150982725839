<template>
  <div>

  <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    color="green"
  >
    {{ alerta }}
  </v-snackbar> 

  <v-container fluid>
      <v-form ref="form" v-model="form_valido" lazy-validation>  
        <v-card outlined elevation="4" >
          <v-card-title>
          </v-card-title>
          
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="visitante.cpf" dense outlined label="Informe o seu CPF" :rules="[...Rules.Required, ...regra_cpf(visitante.cpf)]"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn  class="mr-3" color="primary" @click="verificaCPF"> <v-icon>mdi-arrow-right-thick</v-icon> Pesquisar </v-btn>
                <v-btn  class="mr-3" color="grey"   to="/"> Voltar </v-btn>
              </v-col>
            </v-row>
          </v-card-text> 
          <v-overlay
            :absolute="true"
            :value="loading"
          >
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay> 
        </v-card> 
      </v-form> 

      <br v-if="situacao != 'NAO' && situacao != null">

      <v-card outlined elevation="4" v-if="situacao == 'PA'">
        <v-card-text>
          <h3 class="text--secondary" > Verificamos que este CPF é de um participante que já possui uma senha cadastrada. Caso você não lembre a senha clique <a href="https://participante.serpros.com.br/PagAcesso/EsqueciSenha.aspx">aqui.</a> Você será redirecionado para a área restrita do participante. Após recuperar sua senha volte para este sistema e tente entrar novamente.</h3>
        </v-card-text>
        <v-overlay
          :absolute="true"
          :value="loading"
        >
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> 
      </v-card> 

      <v-card outlined elevation="4" v-if="situacao == 'NA'">
        <v-card-text>
          <h3 class="text--secondary" > Verificamos que este CPF é de um participante que não possui senha cadastrada. Para criar uma senha clique <a href="https://restrito.serpros.com.br/PagAcesso/PrimeiroAcesso.aspx">aqui.</a> Você será redirecionado para a área restrita do participante. Após obter sua senha volte para este sistema e tente entrar novamente.</h3>
        </v-card-text>
        <v-overlay
          :absolute="true"
          :value="loading"
        >
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> 
      </v-card> 

      <v-card outlined elevation="4" v-if="situacao == 'PE'">
        <v-card-text>
          <h3 class="text--secondary" > Verificamos que este CPF é de um pensionista que possui senha cadastrada.  Caso você não lembre a senha clique <a href="https://restrito.serpros.com.br/PagAcesso/EsqueciSenha.aspx">aqui.</a> Você será redirecionado para a área restrita do participante. Após recuperar sua senha volte para este sistema e tente entrar novamente.</h3>
        </v-card-text>
        <v-overlay
          :absolute="true"
          :value="loading"
        >
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> 
      </v-card> 

      <v-card outlined elevation="4" v-if="situacao == 'NE'">
        <v-card-text>
          <h3 class="text--secondary" > Verificamos que este CPF é de um pensionista que não possui senha cadastrada. Para criar uma senha clique <a href="https://restrito.serpros.com.br/PagAcesso/PrimeiroAcesso.aspx">aqui.</a> Você será redirecionado para a área restrita do participante. Após obter sua senha volte para este sistema e tente entrar novamente.</h3>
        </v-card-text>
        <v-overlay
          :absolute="true"
          :value="loading"
        >
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> 
      </v-card> 

      <v-card outlined elevation="4" v-if="situacao == 'VI' || situacao == 'PF'">
        <v-card-text>
          <h3 class="text--secondary" > Verificamos que este CPF já está cadastrado. Você pode criar uma nova senha caso necessário. Um código de confirmação será enviado para o seu e-mail para validar essa operação.</h3>
        </v-card-text>
        <v-overlay
          :absolute="true"
          :value="loading"
        >
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> 
      </v-card> 

      <br>

      <v-form ref="form2" v-model="form_valido" lazy-validation>  
        <v-row v-if="visitante.cpf != null && mostra_formulario && situacao =='NAO'">
          <v-col>
            
              <v-card>
                <v-toolbar color="primary" flat dense dark>
                      <v-toolbar-title>
                        <v-row align="center">
                            <v-col class="shrink">Informações cadastrais</v-col>
                        </v-row>
                      </v-toolbar-title>
                </v-toolbar>
                    <v-card-text>
                    <div>
                      <v-row class="mb-n5">
                        <v-col cols="12" md="6"> 
                          <v-text-field dense v-model="visitante.nome" label="* Nome"  autocomplete="none" :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                        </v-col>  
                       
                      </v-row>  
                      <v-row class="mb-n5">

                        <v-col cols="12" md="3">
                          
                          <comp-data v-model="visitante.data_nascimento" titulo="* Data de nascimento" :outlined="false" :rounded="false"  :obrigatorio="true" :dense="true" :rules="[...Rules.Required]"/>
                        </v-col> 
                      </v-row> 
                      <v-row class="mb-n5">
                        <v-col cols="12" md="3"> 
                          <v-select
                            label="Sexo"
                            v-model="visitante.sexo"
                            :items="sexo"
                            item-text="descricao"
                            item-value="id"
                            dense
                            :rules="[...Rules.Required]"
                            autocomplete="none"
                          > 
                          </v-select>
                        </v-col>
                      </v-row>
                     

                      <v-row class="mb-n5">
                        <v-col cols="12" md="6">
                          <v-text-field autocomplete="none" dense v-model="visitante.email_pessoal" label="* Email Pessoal"  :rules="[...Rules.Required, ...maxLength(60), ...email(visitante.email_pessoal)]"></v-text-field>
                        </v-col> 
                      </v-row>

                      <v-row class="mb-n5">
                        <v-col cols="12" md="3">
                          <v-text-field dense autocomplete="none" v-model="visitante.celular" label="* Celular"  :rules="[...Rules.Required, ...maxLength(15), ...telefone(visitante.celular)]"></v-text-field>
                        </v-col> 
                      </v-row>

                      <v-row class="mb-n5">
                        <v-col cols="12" md="3">
                          <v-text-field type="password" autocomplete="none" dense v-model="visitante.senha" label="* Senha"  :rules="[...Rules.Required, ...maxLength(8), ...RegraSenha, ...RegraNumero(visitante.senha)]"></v-text-field>
                        </v-col> 
                      </v-row>

                      <v-row class="mb-n5"> 
                        <v-col cols="12" md="3">
                          <v-text-field type="password" autocomplete="none" dense v-model="visitante.confirmar_senha" label="Confirmar senha" :rules="[...Rules.Required, ...maxLength(8), ...RegraSenha, ...RegraNumero(visitante.confirmar_senha)]"></v-text-field>
                        </v-col> 
                      </v-row>
                    </div>  
                    </v-card-text>
                    <v-card-actions>
                     <v-spacer></v-spacer>
                     <v-btn class="mb-2" color="primary"  @click="CriaCodigo" > Salvar </v-btn>
                     <v-btn class="mr-2 mb-2" dark color="blue-grey darken-3" to="/" > Sair </v-btn>
                  </v-card-actions>
                  <v-overlay
                    :absolute="true"
                    :value="loading2"
                  >
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>
              </v-card>
          </v-col>
        </v-row>
      </v-form>  

      <v-form ref="form3" v-model="form_valido" lazy-validation>  
        <v-row v-if="visitante.cpf != null && mostra_formulario && (situacao =='VI' || situacao =='PF') ">
          <v-col>
            
              <v-card>
                <v-toolbar color="primary" flat dense dark>
                      <v-toolbar-title>
                        <v-row align="center">
                            <v-col class="shrink">Nova Senha</v-col>
                        </v-row>
                      </v-toolbar-title>
                </v-toolbar>
                    <v-card-text>
                    <div>

                      <v-row class="mb-n5">
                        <v-col cols="12" md="3">
                          <v-text-field type="password" autocomplete="none" dense v-model="visitante.senha" label="* Senha"  :rules="[...Rules.Required, ...maxLength(8), ...RegraSenha, ...RegraNumero(visitante.senha)]"></v-text-field>
                        </v-col> 
                      </v-row>

                      <v-row class="mb-n5"> 
                        <v-col cols="12" md="3">
                          <v-text-field type="password" autocomplete="none" dense v-model="visitante.confirmar_senha" label="Confirmar senha" :rules="[...Rules.Required, ...maxLength(8), ...RegraSenha, ...RegraNumero(visitante.confirmar_senha)]"></v-text-field>
                        </v-col> 
                      </v-row>
                    </div>  
                    </v-card-text>
                    <v-card-actions>
                     <v-spacer></v-spacer>
                     <v-btn class="mb-2" color="primary"  @click="CriaCodigo" > Salvar </v-btn>
                     <v-btn class="mr-2 mb-2" dark color="blue-grey darken-3" to="/" > Sair </v-btn>
                  </v-card-actions>
                  <v-overlay
                    :absolute="true"
                    :value="loading2"
                  >
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>
              </v-card>
          </v-col>
        </v-row>
      </v-form>  

        <v-row v-if="mostra_codigo">
          <v-col>
              <v-card >
                <v-toolbar color="primary" flat dense dark>
                      <v-toolbar-title>
                        <v-row align="center">
                            <v-col class="shrink">Código de confirmação</v-col>
                        </v-row>
                      </v-toolbar-title>
                </v-toolbar>
                    <v-card-text class="justify-center">


                      <v-row class="mb-n5">
                        <v-col cols="12" md="12" class="d-flex justify-center">
                          <div  class="text-h6"  >Um código de confirmação foi enviado para {{ visitante.email_pessoal }}. Digite o código de confirmação no espaço abaixo:</div> 
                        </v-col>   
                      </v-row>  
                      <v-row class="mb-n5" justify="center">
                        <v-col cols="12" md="3" > 
                          
                          <div class="ma-auto position-relative" style="max-width: 300px">
                              <v-otp-input
                                length="6"
                                @finish="Salvar"
                                v-model="codigo_confirmacao"
                              ></v-otp-input>
                          </div>  
                        </v-col>   
                      </v-row> 

                      <v-row >
                      <v-col cols="12" >
                        <div class="text-center" >
                            <v-btn  dark text color="#0D47A1"  @click="ReenviarCodigo" > Enviar novo código </v-btn>
                        </div>

                      </v-col>
                    </v-row> 

                    </v-card-text>
                    <br>
                    <v-overlay
                      :absolute="true"
                      :value="loading2"
                    >
                      <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
              </v-card>
          </v-col>
        </v-row>

        <v-row v-if="codigo_confirmado">
          <v-col>
              <v-card >
                <v-toolbar color="primary" flat dense dark>
                      <v-toolbar-title>
                        <v-row align="center">
                            <v-col class="shrink">Código confirmado</v-col>
                        </v-row>
                      </v-toolbar-title>
                </v-toolbar>
                    <v-card-text>
                      <v-row class="mb-n5">
                        <v-col cols="12" md="12" class="text-center"> 
                          <div  class="text-h6"  >Seu código foi confirmado. Agora faça login com seu CPF e sua senha para acessar o sistema.</div> 
                        </v-col>   
                      </v-row>  
                      <v-row class="mb-n5">
                        <v-col cols="12" md="12" class="text-center"> 
                            <div class="text-center">
                              <v-btn plain color="primary"  to="/login"> Clique aqui para fazer login! </v-btn>
                            </div>
                        </v-col>   
                      </v-row> 
                    </v-card-text>
                    <br>
                    <v-overlay
                      :absolute="true"
                      :value="loading2"
                    >
                      <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
              </v-card>
          </v-col>
        </v-row>

        <br v-if="!autorizado && !loading && !loading2"> 
 
        <v-row>
          <v-col class="text-right">
            <v-btn rounded class="mr-3" color="#B0BEC5"   to="/requerimentos" v-if="autorizado && !loading"> <v-icon>mdi-file-multiple-outline</v-icon> Requerimentos </v-btn>
            <v-btn rounded class="mr-3" color="#B0BEC5"   to="/novo" v-if="autorizado && !loading"> <v-icon>mdi-file-outline</v-icon> Inserir Documento </v-btn>
          </v-col>
        </v-row>  

        <v-overlay
          absolute
          :value="dialog"
        >
        </v-overlay>

        <v-snackbar
          v-model="dialog"
          multi-line
          centered
          :color="cor_dialogo"
          timeout="-1"
          max-width="400"
          min-height="140"
          vertical
        >
          {{ mensagem }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnOk(titulo)"
              v-if="!sim_nao"
            >
              Ok
            </v-btn>
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnSim(titulo)"
              v-if="sim_nao"
            >
              Sim
            </v-btn>
                    <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnNao(titulo)"
              v-if="sim_nao"
            >
              Não
            </v-btn>
          </template>
        </v-snackbar> 
  </v-container>
  </div>
</template>

<script>
    import CompData from '@/components/CompData.vue';    
    export default {
      components: {
        CompData,
      },
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            multiLine: true,
            absolute: false,
            form_valido: true,
            loading: false,
            loading2:false,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            titulo: null,
            sim_nao: false,
            reverse: false,
            resposta: false,
            autorizado: false,
            exibe_alerta: false,
            alerta: '',
            mostra_formulario: false,
            paises:[],
            estados:["AC", "AL",	"AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"],
            nacionalidade:[],
            estado_civil:[],
            escolaridade:[],
            visitante: {
              cpf:'',
              nome: '',
              data_nascimento: '',
              sexo:'',
              email_pessoal: '',
              celular: '',
              senha: '',
              confirmar_senha: ''
            },
            sexo:[
              {id:"M", descricao:"MASCULINO"},
              {id:"F", descricao:"FEMININO"},
            ],
            mostra_codigo: false,
            codigo_confirmacao: null,
            codigo_confirmado: false,
            situacao: null,
            reenvio: false
            
         }
      },


      created: function() {
        var texto = '<p style="color:white; font-size: 12px;">Informe o seu CPF. </p>';

        this.visitante.cpf = '';
        this.situacao = '';

        this.$store.dispatch('exibeajuda',texto);

        this.recuperaListas();

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

      },


      methods: {

        regra_cpf: function(cpf){
          let valido = this.validarCPF(cpf);
          return valido || 'CPF inválido'
        },


        maxLength: function(max) {
          return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
        },


        telefone: function(telefone) {
          
          let aprovado = true;
          let mensagem = '';

          if(telefone != null){

            if(telefone.length < 10){
              aprovado = false;
              mensagem = 'Mínimo: ddd + 8 números';
            }

            if(telefone.indexOf('0000000') != -1 || 
              telefone.indexOf('1111111') != -1 || 
              telefone.indexOf('2222222') != -1 || 
              telefone.indexOf('3333333') != -1 || 
              telefone.indexOf('4444444') != -1 || 
              telefone.indexOf('5555555') != -1 || 
              telefone.indexOf('6666666') != -1 || 
              telefone.indexOf('7777777') != -1 || 
              telefone.indexOf('8888888') != -1 || 
              telefone.indexOf('9999999') != -1 || 
              telefone.indexOf('1234567') != -1
              ){

              aprovado = false;
              mensagem = 'Número inválido';
            }
          }  

          return  aprovado || mensagem
        },


        


        email: function(email) {

          var resultado = true;
          var mensagem = '';

          if (email != null && email != ''){
            var upper_email = email.toUpperCase();
            var indexarroba=upper_email.indexOf("@");
            var indexponto=upper_email.lastIndexOf(".");
            

            if (indexarroba < 1 || indexponto < indexarroba+2 || indexponto+2>=upper_email.length){
              resultado = false;
              mensagem = 'E-mail inválido';
            }

            if( upper_email.indexOf("SERPROS.COM") != -1 || upper_email.indexOf(".GOV.BR") != -1 ){
              resultado = false;
              mensagem = 'use seu e-mail particular';
            }
            
          }
          
          return  resultado || mensagem
        },

        RegraNumero: function(texto) {

          var resultado = true;
          var mensagem = '';

          if(isNaN(texto)){
            resultado = false;
            mensagem = 'Somente números';
          }
          
          return  resultado || mensagem
        },


        RegraSenha: function() {

          var resultado = true;
          var mensagem = '';

          if(this.visitante.senha != '' && this.visitante.confirmar_senha != '' && this.visitante.senha != this.visitante.confirmar_senha){
            resultado = false;
            mensagem = 'Senhas diferentes';
          }
          
          return  resultado || mensagem
        },

        

        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 




        verificaCPF(){

          this.form_valido = false;
          if(this.$refs.form.validate() ){

            this.loading = true;

            let data = {
                cpf: this.visitante.cpf.replace(/[^\d]+/g,''),
            };
            
            this.$store.state.API.post( '/api/verificacpf',  data)
            .then(response => {
                
                if(response.data =="NAO" || response.data =="VI" || response.data =="PF"){
                  this.codigo_confirmado = false;
                  this.mostra_codigo = false;
                  this.mostra_formulario = true;
                  
                  var texto = '<p style="color:white; font-size: 12px;">Preencha as informações cadastrais para criar uma conta de visitante. Com uma conta você poderá acessar o sistema e preencher um requerimento. </p>'+
                              '<p style="color:white; font-size: 12px;">SENHA - O campo senha deverá ser preencido somente com números. O tamanho máximo da senha é de 8 caracteres.</p>';

                  this.$store.dispatch('exibeajuda',texto);
                }
                  
                this.situacao = response.data;

                
                
                this.loading = false;
            })
            .catch(error => {
                this.TrataErros(error);
            });
          }
             
          
        },

        VerificaPais: function(){

          if(this.visitante.id_pais != 31){
             this.visitante.cep = '';
             this.visitante.estado = '';
          }
        },

        //#####################
        //chamadas nas APIs
        //####################
        consultaCEP(cep, visitante) {
          //busca o cep
          if(cep.length == 8){
            this.axios({
              method: 'get',
              url: 'https://viacep.com.br/ws/'+cep+'/json/',
            })
            .then(response => {
                        if("cep" in response.data){
                            visitante.endereco = response.data.logradouro.toUpperCase();
                            visitante.estado = response.data.uf;
                            visitante.cidade = response.data.localidade.toUpperCase();
                            visitante.bairro = response.data.bairro.toUpperCase();

                        }
            })
            .catch(error => {
                    //Erro ao recuperar os dados da pessoa
                    this.TrataErros(error);
            }); 
          }

        },


        recuperaListas() {
          //busca a pessoa

          this.$store.state.API.get( '/api/listas')
          .then(response => {
              this.paises = response.data.paises; 
              this.nacionalidade = response.data.nacionalidade;
              this.estado_civil = response.data.estadoCivil; 
              this.escolaridade = response.data.escolaridade;
          })
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          }); 

        },



        CriaCodigo() {

          this.form_valido = false;
          if(this.$refs.form2.validate() ){
            
            this.loading2 = true;

            var data = {
              cpf: this.visitante.cpf.replace(/[^\d]+/g,''), 
              email: this.visitante.email_pessoal,
            };
            
            this.$store.state.API.post( '/api/gerarcodigo', data)
            .then(response => {
                if(response.data.codigo =="ok"){
                  this.mostra_formulario = false;
                  this.codigo_confirmado = false;
                  this.mostra_codigo = true;
                  this.loading2 = false;
                  this.loading = false;
                  if(this.reenvio){
                    this.alerta = "Código reenviado";
                    this.exibe_alerta = true;
                  }
                }
            })
            .catch(error => {
                    //Erro ao recuperar os dados da pessoa
                    this.TrataErros(error);
            });
          }     
 



        },


        ReenviarCodigo(){
          this.reenvio = true; 
          this.CriaCodigo();

        },



        

        Salvar(value) {

          if(this.situacao == 'NAO'){

            this.loading2 = true;


            var data = {
              visitante: this.visitante,
              codigo: value,
            };

            data.visitante.cpf = this.visitante.cpf.replace(/[^\d]+/g,'');
            
            this.$store.state.API.post( '/api/validarvisitante', data)
            .then(response => {
                if(response.data.codigo == "ok"){
                  this.mostra_codigo=false;
                  this.codigo_confirmado = true;
                  this.loading = false;
                  this.loading2 = false;
                }else{
                  this.TrataErros(response);
                }
            })
            .catch(error => {
                    //Erro ao recuperar os dados da pessoa
                    this.TrataErros(error);
            }); 

          }else{

            this.loading2 = true;
            var data = {
              cpf: this.visitante.cpf.replace(/[^\d]+/g,''),
              senha: this.visitante.senha,
              codigo: value,
            };
            
            this.$store.state.API.post( '/api/novasenha', data)
            .then(response => {
                if(response.data.codigo == "ok"){
                  this.mostra_codigo=false;
                  this.codigo_confirmado = true;
                  this.loading = false;
                  this.loading2 = false;
                }
            })
            .catch(error => {
                    //Erro ao recuperar os dados da pessoa
                    this.TrataErros(error);
            }); 

          }

           
        },

        //#####################
        //funções utilitárias
        //####################

        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.loading2 = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        },


        validarCPF(cpf) {	
          cpf = cpf.replace(/[^\d]+/g,'');	
          if(cpf == '') return false;	
          // Elimina CPFs invalidos conhecidos	
          if (cpf.length != 11 || 
            cpf == "00000000000" || 
            cpf == "11111111111" || 
            cpf == "22222222222" || 
            cpf == "33333333333" || 
            cpf == "44444444444" || 
            cpf == "55555555555" || 
            cpf == "66666666666" || 
            cpf == "77777777777" || 
            cpf == "88888888888" || 
            cpf == "99999999999")
              return false;		
          // Valida 1o digito	
          let add = 0,
              i = 0,
              rev = 0;	

          for (i=0; i < 9; i ++)		
            add += parseInt(cpf.charAt(i)) * (10 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)		
              rev = 0;	
            if (rev != parseInt(cpf.charAt(9)))		
              return false;		
          // Valida 2o digito	
          add = 0;	
          for (i = 0; i < 10; i ++)		
            add += parseInt(cpf.charAt(i)) * (11 - i);	
          rev = 11 - (add % 11);	
          if (rev == 10 || rev == 11)	
            rev = 0;	
          if (rev != parseInt(cpf.charAt(10)))
            return false;		
          return true;   
        },

      },

   }
</script>