<template>
  <div>
    <br>
    <v-row>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Tipo de pagamento"
          :value="value.tipo_pagamento"
          @input="setTipoPagamento"
          :items="$store.state.tipos_pagamento"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-select
          label="Banco"
          :value="value.banco"
          @input="setBanco"
          :items="bancos"
          item-text="nome"
          item-value="numero"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field
          :value="value.agencia"
          @input="setAgencia"
          label="Agencia"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
          maxlength="5"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="my-0 py-0">
        <v-text-field
          :value="value.conta"
          @input="setConta"
          label="Conta" 
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
          maxlength="8"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" class="my-0 py-0">
        <v-text-field
          value="Resgate"
          label="Benefício"
          readonly
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="my-0 py-0">
        <v-select
          label="Resgate parcelado"
          :value="value.resgate"
          @input="setResgate"
          :items="sim_nao"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
      <v-col cols="12" md="4" class="my-0 py-0" v-if="value.resgate == 'S'">
        <v-select
          label="Parcelas"
          :value="value.parcelas"
          @input="setParcelas"
          :items="lista_parcelas"
          :rules="[...Resgate()]"
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12" md="4" class="my-0 py-0">
        <v-select
          label="Opção I.R.R.F."
          :value="value.opcao_irrf"
          @input="setOpcaoIrrf"
          :items="FiltraOpcaoIRRF(id_plano)"
          item-text="descricao"
          item-value="id"
          :rules="Rules.Required" 
          :readonly="situacao != 'L' && situacao != 'E'"
        > 
        </v-select>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="8" class="text-justify text-h6 ">
        Você está ciente de que ao sair do plano perderá a paridade contributiva?
      </v-col>
      <v-col cols="12" md="4" class="text-justify text-h6 mt-n2">
        <v-switch
          v-model="value.ciente_perda_paridade"
          :label="value.ciente_perda_paridade ? 'Sim' : 'Não'"
          :rules="Rules.Required" 
          @input="setCientePerdaParidade"
        ></v-switch>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8" class="text-justify text-h6 ">
        Você está ciente de que o desligamento do plano é irreversível?
      </v-col>
      <v-col cols="12" md="4" class="text-justify text-h6  mt-n2">
        <v-switch
          v-model="value.ciente_desligamento_irreversivel"
          :label="value.ciente_desligamento_irreversivel ? 'Sim' : 'Não'"
          :rules="Rules.Required" 
          @input="setCienteDesligamentoIrreversivel"
        ></v-switch>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" class="text-justify text-h6 ">
        OBS.: A carta de concessão do INSS deve ser informada pelos participantes que migraram do PSI para o PSII.
      </v-col>
    </v-row>
  </div>  
</template>    

<script>
  
    export default {  
        name: "ReqResgate",
        props: ['value','id_plano','situacao'], 
        data: function(){
          return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            bancos: [
              {numero:"001", nome: "BANCO DO BRASIL"},
              {numero:"104", nome: "CAIXA ECONÔMICA FEDERAL"},
              {numero:"237", nome: "BRADESCO"},
              {numero:"341", nome: "ITAÚ"},
              {numero:"033", nome: "SANTANDER"},
              {numero:"037", nome: "BANPARÁ"},
              {numero:"041", nome: "BANRISUL"},
              {numero:"070", nome: "BANCO DE BRASÍLIA"},
              {numero:"077", nome: "BANCO INTER"},
              {numero:"290", nome: "PAGBANK - PAGSEGURO INTERNET"},
              {numero:"323", nome: "MERCADO LIVRE"},
              {numero:"336", nome: "BANCO C6"},
              {numero:"348", nome: "BANCO XP SA"},
              {numero:"389", nome: "BANCO MERCANTIL DO BRASIL"},
              {numero:"399", nome: "HSBC"},
              {numero:"422", nome: "SAFRA"},
              {numero:"735", nome: "NEON"},
              {numero:"745", nome: "CITIBANK"},
              {numero:"208", nome: "PACTUAL"},
              {numero:"212", nome: "BANCO ORIGINAL"},
              {numero:"260", nome: "NUBANK"},
              {numero:"748", nome: "BANCO SICREDI"},
              {numero:"756", nome: "BANCOOB"},

            ],
            opcoes_irrf: [
              {id:"P", descricao: "PROGRESSIVO"},
              {id:"R", descricao: "REGRESSIVO"},
            ],
            tipo_pagamento: this.value.tipo_pagamento,
            banco: this.value.banco,
            agencia: this.value.agencia,
            conta: this.value.conta,
            resgate: this.value.resgate,
            parcelas: this.value.parcelas,
            ciente_perda_paridade: this.value.ciente_perda_paridade,
            ciente_desligamento_irreversivel: this.value.ciente_desligamento_irreversivel,
            opcao_irrf: this.value.opcao_irrf,
            sim_nao:[
              {id:'S', descricao:'SIM'},
              {id:'N', descricao:'NÃO'},
            ],
            lista_parcelas: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60],

          }
        },  
        methods: {

          FiltraOpcaoIRRF: function(id_plano){
         
            return this.opcoes_irrf.filter(function(opcao_irrf) {
              var exibe = true;
              if(id_plano == 1 && opcao_irrf.id =="R") {
                exibe = false;
              }
              return exibe;
            })
          },

          Resgate: function() {
          
            let aprovado = true;
            let mensagem = '';
 
            if(this.value.resgate == 'S' && this.value.parcelas == null){
              aprovado = false;
              mensagem = 'Obrigatório';
            }

            return  aprovado || mensagem
          },

          FiltraBeneficio: function(id_plano){
          
            return this.$store.state.beneficios.filter(function(beneficio) {
              var exibe = false;
              if(beneficio.id_plano == id_plano ) {
                exibe = true;
              }
              return exibe;
            })
          },
          updateValue() {
           //console.log(this.beneficio);  
            if(this.resgate == 'N'){
              this.parcelas = 0;
            }
            
            this.$emit('input', {
               tipo_pagamento: this.tipo_pagamento,
               banco: this.banco,
               agencia: this.agencia,
               conta: this.conta,
               resgate: this.resgate,
               parcelas: this.parcelas,
               ciente_perda_paridade: this.ciente_perda_paridade,
               ciente_desligamento_irreversivel: this.ciente_desligamento_irreversivel,
               opcao_irrf: this.opcao_irrf
            });
          },
          setBanco(banco){
           this.banco = banco;
           this.updateValue();
          },
          setAgencia(agencia){
           this.agencia = agencia;
           this.updateValue();
          },
          setConta(conta){
           this.conta = conta;
           this.updateValue();
          },
          setTipoPagamento(tipo_pagamento){
           this.tipo_pagamento = tipo_pagamento;
           this.updateValue();
          },
          setResgate(resgate){
           this.resgate = resgate;
           this.updateValue();
          },
          setParcelas(parcelas){
           this.parcelas = parcelas;
           this.updateValue();
          },
          setCientePerdaParidade(ciente_perda_paridade){
           this.ciente_perda_paridade = ciente_perda_paridade;
           this.updateValue();
          },
          setCienteDesligamentoIrreversivel(ciente_desligamento_irreversivel){
           this.ciente_desligamento_irreversivel = ciente_desligamento_irreversivel;
           this.updateValue();
          },
          setOpcaoIrrf(opcao_irrf){
           this.opcao_irrf = opcao_irrf;
           this.updateValue();
          },
        }
    }
</script>