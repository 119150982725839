<template>
  <div>

  <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
  >
    {{ alerta }}
  </v-snackbar> 

  <v-container fluid>
    <v-card  elevation="4" >
      <v-card-title>
      Tipos de requerimento
      </v-card-title>
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel
          v-for="tipo in tipos" 
          >
            <v-expansion-panel-header class="text-h6 primary--text">
              {{ tipo.descricao }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ tipo.detalhes }} 
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <v-btn :disabled="carregando || (tipo.id==3 && $store.state.permissao_resgate=='N')" depressed color="blue-grey" class="white--text" @click="ChamaFormulario(tipo.id)"> Iniciar requerimento </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>  

    <br>

    <v-row>
      <v-col class="text-right">
        <v-btn rounded  color="#B0BEC5" to="/"  > <v-icon>mdi-arrow-left-circle</v-icon> Voltar </v-btn>
      </v-col>
    </v-row> 
    
     
    

    <v-overlay
      :absolute="absolute"
      :value="dialog"
    >
    </v-overlay>

    <v-snackbar
      v-model="dialog"
      :multi-line="multiLine"
      centered
      :color="cor_dialogo"
      timeout="-1"
      max-width="400"
      min-height="140"
      vertical
    >
      {{ mensagem }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnOk()"
          v-if="!sim_nao"
        >
          OK
        </v-btn>
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnSim(titulo)"
          v-if="sim_nao"
        >
          Sim
        </v-btn>
                <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="BtnNao()"
          v-if="sim_nao"
        >
          Não
        </v-btn>
      </template>
    </v-snackbar>
 
  </v-container>
  </div>
</template>

<script>

   export default {

      data: function(){
         return {

            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            absolute: false,
            dialog: false,
            multiLine: true,
            cor_dialogo: '#455A64',
            loading: false,
            overlay: false,
            titulo: null,
            excluindo: false,
            mensagem: '',
            sim_nao: false,
            carregando: false,
            tipos: [],


         }
      },
      created: function() {
        
        var texto = '<p style="color:white; font-size: 14px;">- Escolha um tipo de requerimento.';

        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
            this.alerta = this.$store.state.alerta;
            this.$store.commit('setalerta','');
            this.alerta_color = "green";
            this.exibe_alerta = true;
        }

        this.tipos = this.FiltraTipoRequerimento();
        

      },

      methods: {

        //#####################
        //filtra os tipos de requerimento
        //####################
        FiltraTipoRequerimento: function(){

          var tipos = [];
        
          if(this.$store.state.plano_participante.length > 0){

            const qtd_planos = this.$store.state.plano_participante.length;
            const planos = this.$store.state.plano_participante;

            console.log(this.$store.state.tipos_req);
           
            tipos =  this.$store.state.tipos_req.filter(function(tipo_req) {

              var exibe = false;
              
              for (var i = 0; i < qtd_planos; i++) {
                  if(tipo_req.id_plano == planos[i].id_plano){

                    if (tipo_req.id == 2 || tipo_req.id == 4 || tipo_req.id == 5 || tipo_req.id == 6 || tipo_req.id == 10) {
                      exibe = false;
                    }else{
                      exibe = true;
                    }
                  }else{
                    if(tipo_req.id == 11){
                      exibe = true;
                    }
                  }
              }

              return exibe;
            })

          }else{

            tipos = this.$store.state.tipos_req.filter(function(tipo_req) {
              var exibe = false;
              
              //if(tipo_req.id == 4 || tipo_req.id == 5 || tipo_req.id == 6 || tipo_req.id == 11){
              if(tipo_req.id == 11){
                exibe = true;
              }
                      
              return exibe;
            
            });

          }

          //remove da lista requerimentos iguais
          var qtd_tipos = tipos.length;
          var tipos_unicos = [];
          var tipo_aux= undefined;

          for (var x = 0; x < qtd_tipos; x++) {
              
              tipo_aux = tipos_unicos.find(tipo => tipo.id == tipos[x].id);
              
              if(tipo_aux == undefined){
                 tipos_unicos.push(tipos[x]);
              }
                  
          }

          return tipos_unicos;
          
        },

        
        //#####################
        // Função que chama o formulário de requerimento 
        // correspondente ao tipo de requerimento selecionado
        //#####################

        ChamaFormulario(id_tipo_req){

          this.carregando = true;

          if(id_tipo_req == 1 || id_tipo_req == 2 || id_tipo_req == 3 || id_tipo_req == 7 || id_tipo_req == 8 || id_tipo_req == 13){
              this.$router.push('/requerimento01/undefined/'+id_tipo_req);
          }else if(id_tipo_req == 11){
              this.$router.push('/requerimento03');
          }else{
              this.$router.push('/requerimento02/undefined/'+id_tipo_req);
          }
          
        },

        

        //#####################
        // click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        },   
        
        BtnSim(titulo){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          if(titulo == 'Pergunta1'){
            this.salvaRequerimento();
          } 

          if(titulo == 'Alerta'){
           this.sair();
          }
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 



        sair(){

          var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        }, 


        



        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        }
        

      },
   }
</script>